<template>
  <div class="d-flex justify-space-between align-baseline my-2 ">
    <div class="font-weight-bold mx-3">
      Usage: {{ limits.usedImages }}/{{ limits.maxImages }}
    </div>
    <div
      class="text-center bg-primary"
    >
      <v-btn
        class="mx-3"
        color="primary"
        x-large
        @click="copyToClipBoard"
      >
        <v-icon class="mr-2 pointer">
          {{ icons.mdiImage }}
        </v-icon>
        <label class="pointer">Copy</label>
      </v-btn>

      <v-btn
        color="primary"
        x-large
        @click="exportImage"
      >
        <v-icon class="mr-2 pointer">
          {{ icons.mdiDownload }}
        </v-icon>
        <label class="pointer">Download</label>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiDownload, mdiImage } from '@mdi/js'

export default {
  props: {
    limits: {
      type: Object,
      required: true,
    },
    exportImage: {
      type: Function,
      required: true,
    },
    copyToClipBoard: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiDownload,
        mdiImage,
      },
    }
  },
}
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
