var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-slider',{attrs:{"max":"2500","min":"300"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Intensity "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.resetSingleEffect('intensity',800)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])]}}])},[_c('span',[_vm._v("Reset")])])]},proxy:true}]),model:{value:(_vm.intensity),callback:function ($$v) {_vm.intensity=$$v},expression:"intensity"}}),_c('v-slider',{attrs:{"max":"45","min":"-45"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tilt X "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.resetSingleEffect('x',0)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])]}}])},[_c('span',[_vm._v("Reset")])])]},proxy:true}]),model:{value:(_vm.x),callback:function ($$v) {_vm.x=$$v},expression:"x"}}),_c('v-slider',{attrs:{"max":"45","min":"-45"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tilt Y "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.resetSingleEffect('y',0)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])]}}])},[_c('span',[_vm._v("Reset")])])]},proxy:true}]),model:{value:(_vm.y),callback:function ($$v) {_vm.y=$$v},expression:"y"}}),_c('v-slider',{attrs:{"max":"45","min":"-45"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tilt Z "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.resetSingleEffect('z',0)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])]}}])},[_c('span',[_vm._v("Reset")])])]},proxy:true}]),model:{value:(_vm.z),callback:function ($$v) {_vm.z=$$v},expression:"z"}}),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.resetEffects}},[_vm._v(" Reset Filters ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }