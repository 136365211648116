<template>
  <div class="app__sidebar">
    <!-- card settings -->
    <div class="section ">
      <label>Select Browser</label>
      <div class="card__settings">
        <div
          v-for="(browser,i) in browsers"
          :key="i"
          :class="`browser-box d-flex pa-1 flex-column align-center ${browser.id == settings.browser ? 'active' : ''}`"
          @click="updateBrowser(browser)"
        >
          <img
            width="40"
            height="40"
            :src="browser.image"
          >
          <span>{{ browser.name }}</span>
        </div>
      </div>
    </div>

    <v-divider></v-divider>

    <div class="card__settings px-6">
      <div
        v-if="settings.themeSwitch"
        class="action-box d-flex flex-column align-center"
      >
        <div
          class="icon"
          @click="toggleTheme"
        >
          <v-icon
            v-if="settings.theme == 'light'"
            class="rotate icon"
          >
            {{ icons.mdiMoonWaningCrescent }}
          </v-icon>
          <v-icon
            v-else
            class="icon"
          >
            {{ icons.mdiWhiteBalanceSunny }}
          </v-icon>
        </div>
        <span>{{ settings.theme == 'light' ? 'Dark' : 'Light' }} </span>
      </div>

      <div class="action-box d-flex flex-column align-center">
        <div
          class="icon"
          @click="toggleURL"
        >
          <v-icon
            class=" icon"
          >
            {{ icons.mdiWeb }}
          </v-icon>
        </div>
        <span :class="settings.url.enabled ? '' : 'text-decoration-line-through'">URL</span>
      </div>

      <div class="action-box d-flex flex-column align-center">
        <div
          class="icon"
          @click="toggleShadow"
        >
          <v-icon
            width="50"
            size="25"
          >
            {{ icons.mdiBoxShadow }}
          </v-icon>
        </div>
        <span :class="settings.shadow.enabled ? '' : 'text-decoration-line-through'"> Shadow</span>
      </div>
    </div>

    <div
      class="section"
    >
      <v-text-field
        v-if="settings.url.enabled"
        v-model="settings.url.value"
        class="mt-5 "
        label="Enter URL"
        placeholder="http://url.com"
        :prepend-inner-icon="icons.mdiWeb"
        :maxlength="settings.browser == 'safari-browser' ? 50 : 110"

        outlined
        dense
      ></v-text-field>

      <v-text-field
        v-if="settings.url.enabled && settings.browser === 'chrome-browser'"
        v-model="settings.title.value"
        maxlength="18"
        dense
        label="Enter Title"
        outlined
      ></v-text-field>
    </div>

    <v-divider></v-divider>

    <div
      v-if="settings.shadow.enabled"
      class="section mb-0"
    >
      <label>Shadow Intensity</label>

      <div class="mt-2">
        <v-slider
          v-model="shadowIntensity"
          max="10"
          min="0"
        ></v-slider>
      </div>
    </div>

    <v-expansion-panels class="pa-1">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Padding Control
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <span>Horizontal</span>
            <v-slider
              v-model="horizontalPadding"
              max="100"
              min="0"
            ></v-slider>
          </div>

          <div>
            <span>Vertical</span>
            <v-slider
              v-model="verticalPadding"
              max="150"
              min="0"
            ></v-slider>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- backgrounds -->
      <v-expansion-panel>
        <v-expansion-panel-header>
          Backgrounds
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <backgrounds-card></backgrounds-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <v-icon>{{ icons.mdiRotate3d }}</v-icon> Effects
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <EffectsCard />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiRotate3d, mdiMoonWaningCrescent, mdiWhiteBalanceSunny, mdiWeb, mdiBoxShadow } from '@mdi/js'
import BackgroundsCard from './BackgroundsCard.vue'
import EffectsCard from './EffectsCard.vue'
import DB from '@/@fake-db/data/browserShotDB'

export default {
  components: {
    BackgroundsCard,
    EffectsCard,
  },
  data() {
    return {
      browsers: DB.browsers,
      icons: {
        mdiMoonWaningCrescent,
        mdiWhiteBalanceSunny,
        mdiWeb,
        mdiBoxShadow,
        mdiRotate3d,
      },
    }
  },
  computed: {
    ...mapState({
      settings: state => state.tools.browsershots.settings,
    }),
    shadowIntensity: {
      get() {
        return this.settings.shadow.intensity
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_SHADOW_INTENSITY', value)
      },
    },
    horizontalPadding: {
      get() {
        return this.settings.padding.horizontal
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_PADDING', {
          horizontal: value,
          vertical: this.verticalPadding,
        })
      },
    },

    verticalPadding: {
      get() {
        return this.settings.padding.vertical
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_PADDING', {
          horizontal: this.horizontalPadding,
          vertical: value,
        })
      },
    },
  },
  methods: {
    ...mapActions('tools/browsershots', ['toggleTheme', 'toggleURL', 'updateBrowser', 'toggleShadow']),
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/tools-sidebar.scss';
.browser-box {
  padding: 10px;
  width: 4.4rem;
  cursor: pointer;
}
.browser-box:hover,
.browser-box.active {
  background: #46506e;
  span {
    color: white;
  }
}
</style>
