<template>
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 891 44"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :style="`border-radius: 8px 8px 0px 0px; background-color: ${settings.theme == 'light' ? 'rgb(232, 232, 232)' : 'black'}`"
  >
    <defs v-if="settings.url.enabled">
      <rect
        id="a"
        x="90"
        y="10"
        width="80%"
        height="24"
        rx="4"
        fill="#FFF"
      ></rect>

      <filter
        id="b"
        x="-.1%"
        y="-1.7%"
        width="100.2%"
        height="103.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="1"
          in="SourceAlpha"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
          in="shadowInnerInner1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        width="100%"
        fill="#e8e8e8"
      ></rect>
      <use
        fill="#FFF"
        xlink:href="#a"
      ></use>

      <text
        v-if="settings.url.enabled"
        x="96"
        y="24"
        dominant-baseline="middle"
        font-size="12"
        fill="#717171"
      >{{ settings.url.value }}</text>

      <use
        fill="#000"
        filter="url(#b)"
        xlink:href="#a"
      ></use>
      <circle
        stroke="#F04744"
        fill="#FF5F56"
        fill-rule="nonzero"
        cx="20"
        cy="22"
        r="6"
      ></circle>
      <circle
        stroke="#EBA03F"
        fill="#FFBD2E"
        fill-rule="nonzero"
        cx="40"
        cy="22"
        r="6"
      ></circle>
      <circle
        stroke="#13AB42"
        fill="#27C93F"
        fill-rule="nonzero"
        cx="60"
        cy="22"
        r="6"
      ></circle>
    </g>
  </svg>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      settings: state => state.tools.browsershots.settings,
    }),
  },
}
</script>
