<template>
  <v-app>
    <div
      class="app__main "
      :style="styleVars"
    >
      <div class="w-full h-full d-flex flex-wrap">
        <v-col
          cols="12"
          md="8"
        >
          <div class="app__top w-full text-center d-flex align-center border">
            <img
              class="mr-3"
              width="35"
              height="35"
              :src="require('@/assets/images/browsershots/browsershot-icon.svg')"
            />
            <h2>
              BrowserShots
            </h2>
          </div>

          <v-row>
            <v-col
              :cols="exportPreview ? '12' : '12'"
              :style="exportPreview ? `max-width:${card.selectedSize.width}px;min-width:${card.selectedSize.width}px;padding:0` : 'width:auto;'"
            >
              <div
                class="transparent__bg"
              >
                <div
                  ref="exportBlock"
                  tabindex="0"
                  class="canvas-container"
                  :style="exportPreview ? `width:${card.selectedSize.width}px;height:${card.selectedSize.height}px` : ''"
                >
                  <div
                    class="canvas d-flex justify-center align-center"
                    :style="`height:100%;background-image: none;${resolvePadding};${ resolveContainerBackground };`"
                  >
                    <div
                      id="window-container"
                      style="width:100%"
                    >
                      <component :is="settings.browser"></component>
                      <div
                        v-if="image.ready"
                        class="text-center"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-img
                            width="100%"
                            :src="image.ready && image.path ? image.path : require('@/assets/images/placeholders/placeholder-new-products.png')"
                          >
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out  darken-2 v-card--reveal text-h2 white--text"
                                style="height: 100%;"
                              >
                                <v-btn
                                  v-if="!exportPreview"
                                  elevation="8"
                                  dark
                                  @click="clearImage"
                                >
                                  Remove
                                </v-btn>
                              </div>
                            </v-expand-transition>
                          </v-img>
                        </v-hover>
                      </div>
                      <div v-else>
                        <no-file-card></no-file-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer
                :limits="limits"
                :copy-to-clip-board="copyToClipBoard"
                :export-image="exportImage"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <sidebar />
        </v-col>

        <!-- Contents -->
      </div>
    </div>

    <!-- additional modules -->

    <!-- short loading -->
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar for message -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="2000"
      :light="$vuetify.theme.dark"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiDelete } from '@mdi/js'
import Sidebar from './components/Sidebar.vue'
import Footer from './components/Footer.vue'
import SimpleBrowser from './components/browsers/SimpleBrowser.vue'
import ChromeBrowser from './components/browsers/ChromeBrowser.vue'
import SafariBrowser from './components/browsers/SafariBrowser.vue'
import NoFileCard from './components/NoFileCard.vue'

// load mixins
import ControlMixin from './mixins/Control'

export default {
  metaInfo: {
    title: 'Browser shots',
  },
  components: {
    Sidebar,
    Footer,
    SimpleBrowser,
    ChromeBrowser,
    SafariBrowser,
    NoFileCard,
  },
  mixins: [ControlMixin],
  data() {
    return {
      page: 'twitter-images',
      tempIntensity: null,
      icons: {
        mdiDelete,
      },
    }
  },

  computed: {
    ...mapState({
      loading: state => state.tools.browsershots.loading,
      settings: state => state.tools.browsershots.settings,
      image: state => state.tools.browsershots.image,
    }),

    intensity: {
      get() {
        return this.settings.effects.intensity
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_EFFECTS', {
          value,
          key: 'intensity',
        })
      },
    },

    styleVars() {
      const { colorScheme } = this.$store.state.social

      return {
        '--perspective': `${this.settings.effects.intensity}px`,
        '--transform': `rotateX(${this.settings.effects.x}deg) rotateY(${this.settings.effects.y}deg) rotateZ(${this.settings.effects.z}deg)`,
        '--background-color': colorScheme.backgroundColor,
        '--filter': this.settings.shadow.enabled
          ? `drop-shadow(rgb(0, 0, 0) 0px ${this.settings.shadow.value[0] * this.settings.shadow.intensity}px
          ${this.settings.shadow.value[1] * this.settings.shadow.intensity}px)`
          : 'none',
      }
    },

    resolvePadding() {
      return `padding: ${this.settings.padding.horizontal}px ${this.settings.padding.vertical}px `
    },
    resolveBackground() {
      return this.settings.background
    },
  },

  watch: {
    exportPreview(preview) {
      if (preview) {
        const el = this.$refs.exportBlock
        const height = (el.offsetHeight / el.offsetWidth) * 100
        this.card.selectedSize.height = (1200 / 100) * height
        this.tempIntensity = this.intensity
        this.intensity = this.tempIntensity * 1.6
      } else {
        this.intensity = this.tempIntensity
      }
    },
  },

  created() {
    this.applyDefaultBackground('background:transparent')
  },
  methods: {
    ...mapActions('tools/browsershots', ['clearImage']),
    ...mapActions('social', ['applyDefaultBackground']),
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/tweet-task.scss';

.transparent__bg {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC')
    repeat;
}

.canvas-container {
  text-align: center;
  margin: auto;
  font-size: 0;
  overflow: hidden;

  .canvas {
    perspective: var(--perspective);
    transition: all 0.5s ease-out 0s;
    box-sizing: border-box;
    border-radius: 0px;
  }
  #window-container {
    filter: var(--filter);
    transform: var(--transform);
    transform-origin: center center;
    transition: all 0.5s ease-out 0s;
  }
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
