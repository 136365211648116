import apiClient from '@/services'
import {
  mdiAlertCircleOutline,
  mdiCheckDecagram,
  mdiCircleOutline,
  mdiCircleSlice8,
  mdiClockOut,
  mdiClockOutline,
  mdiContentCopy,
  mdiDelete,
  mdiDeleteCircle,
  mdiDownloadMultiple,
  mdiFormatQuoteOpen,
  mdiFormatSize,
  mdiHeart,
  mdiHeartOutline,
  mdiHistory,
  mdiImage,
  mdiMoonWaningCrescent,
  mdiPlus,
  mdiTwitter,
  mdiWhiteBalanceSunny
} from '@mdi/js'
import { toBlob, toPng } from 'html-to-image'
import { mapActions, mapState } from 'vuex'

const Control = {
  data() {
    return {
      error: null,

      card: {
        theme: 'light',
        selectedSize: { width: 1200, height: 630 },
        exportSize: { width: 1200, height: null },
      },
      exportPreview: false,
      loadingSvg: require('@/assets/images/svg/loader.svg'),

      snackbar: {
        show: false,
        text: null,
      },
      icons: {
        mdiTwitter,
        mdiImage,
        mdiCircleOutline,
        mdiCheckDecagram,
        mdiMoonWaningCrescent,
        mdiHeart,
        mdiCircleSlice8,
        mdiContentCopy,
        mdiDownloadMultiple,
        mdiAlertCircleOutline,
        mdiWhiteBalanceSunny,
        mdiHeartOutline,
        mdiClockOutline,
        mdiClockOut,
        mdiPlus,
        mdiDeleteCircle,
        mdiDelete,
        mdiFormatSize,
        mdiHistory,
        mdiFormatQuoteOpen,
      },
    }
  },
  computed: {
    ...mapState({
      loading: state => state.social.loading,
      logEnabled: state => state.social.logEnabled,
      limits: state => state.social.limits,
      colorScheme: state => state.social.colorScheme,
      activeBackground: state => state.social.activeBackground,
      myBackgrounds: state => state.social.myBackgrounds,
      backgroundDialg: state => state.social.backgroundDialg,
      defaultBackgrounds: state => state.social.defaultBackgrounds,
      fontsDialg: state => state.social.fontsDialg,
      activeFont: state => state.social.activeFont,
      settings: state => state.tools.browsershots.settings,
    }),

    resolveContainerBackground() {
      if (this.colorScheme.backgroundImage) return this.colorScheme.backgroundImage

      return `background:${this.colorScheme.backgroundColor}`
    },

    intensity: {
      get() {
        return this.settings.effects.intensity
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_EFFECTS', {
          value,
          key: 'intensity',
        })
      },
    },
  },
  created() {
    this.fetchUsageLimits()
    if (this.myBackgrounds.length === 0) this.getBackgrounds()
    this.setLog(true)

    // console.log(window.devicePixelRatio)

    // this.$store.state.appConfig.app.contentWidth = 'full'
  },
  methods: {
    ...mapActions('social', [
      'backgroundDialgToggle',
      'loadingToggle',
      'setLog',
      'getBackgrounds',
      'fetchUsageLimits',
      'fontsDialogToggle',
      'applyDefaultBackground',
      'setColorScheme',
    ]),
    ...mapActions('tracking', ['logActivity']),

    notify(text) {
      this.snackbar.show = true
      this.snackbar.text = text
    },
    exportImage() {
      const el = this.$refs.exportBlock

      if (this.checkLimit()) {
        // log tracking active
        this.logExportActivity('MockUpDownload')

        this.exportPreview = true

        setTimeout(() => {
          toPng(el, {
            width: this.card.selectedSize.width,
          })
            .then(dataUrl => {
              const link = document.createElement('a')
              link.download = 'browsershot.png'
              link.href = dataUrl
              link.click()
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
              this.exportPreview = false
            })
        }, 500)
      }
    },
    copyToClipBoard() {
      if (this.checkLimit()) {
        // log tracking active
        this.logExportActivity('MockUpCopy')

        const el = this.$refs.tweetExportRef

        this.exportPreview = true

        setTimeout(() => {
          toBlob(el, {
            skipAutoScale: true,
            width: this.card.selectedSize.width,
            height: this.card.selectedSize.height,
          }).then(blob => {
            navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])
            this.notify('Copied to clipboard')
          })
            .finally(() => {
              this.exportPreview = false
            })
        }, 500)
      }
    },

    logExportActivity(activity) {
      apiClient.toolActivity({
        tool: 'browsershots',
      }).catch(err => console.log(err))

      this.logActivity({
        activityName: activity,
        properties: {
          ...this.settings,
        },
      })
    },

    checkLimit() {
      if (this.logEnabled === false) return true

      if (this.limits.leftImages > 0) {
        this.$store.dispatch('social/logLimit')
        this.setLog(false)

        return true
      }
      this.notify("You don't have any credit left")
      if (process.env.VUE_APP_DOMAIN === 'localhost') return true

      return false
    },
  },
}
export default Control
