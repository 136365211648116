<template>
  <div>
    <v-slider
      v-model="intensity"
      max="2500"
      min="300"
    >
      <template v-slot:label>
        Intensity
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="resetSingleEffect('intensity',800)"
            >
              {{ icons.mdiRefresh }}
            </v-icon>
          </template>
          <span>Reset</span>
        </v-tooltip>
      </template>
    </v-slider>

    <v-slider
      v-model="x"
      max="45"
      min="-45"
    >
      <template v-slot:label>
        Tilt X
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="resetSingleEffect('x',0)"
            >
              {{ icons.mdiRefresh }}
            </v-icon>
          </template>
          <span>Reset</span>
        </v-tooltip>
      </template>
    </v-slider>

    <v-slider
      v-model="y"
      max="45"
      min="-45"
    >
      <template v-slot:label>
        Tilt Y
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="resetSingleEffect('y',0)"
            >
              {{ icons.mdiRefresh }}
            </v-icon>
          </template>
          <span>Reset</span>
        </v-tooltip>
      </template>
    </v-slider>

    <v-slider
      v-model="z"
      max="45"
      min="-45"
    >
      <template v-slot:label>
        Tilt Z
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="resetSingleEffect('z',0)"
            >
              {{ icons.mdiRefresh }}
            </v-icon>
          </template>
          <span>Reset</span>
        </v-tooltip>
      </template>
    </v-slider>

    <v-btn
      color="primary"
      outlined
      @click="resetEffects"
    >
      Reset Filters
    </v-btn>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { mdiRefresh } from '@mdi/js'

export default {
  data() {
    return {
      icons: { mdiRefresh },
    }
  },
  computed: {
    ...mapState({
      settings: state => state.tools.browsershots.settings,
    }),

    intensity: {
      get() {
        return this.settings.effects.intensity
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_EFFECTS', {
          value,
          key: 'intensity',
        })
      },
    },
    x: {
      get() {
        return this.settings.effects.x
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_EFFECTS', {
          value,
          key: 'x',
        })
      },
    },

    y: {
      get() {
        return this.settings.effects.y
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_EFFECTS', {
          value,
          key: 'y',
        })
      },
    },

    z: {
      get() {
        return this.settings.effects.z
      },
      set(value) {
        this.$store.commit('tools/browsershots/UPDATE_EFFECTS', {
          value,
          key: 'z',
        })
      },
    },
  },

  methods: {
    ...mapActions('tools/browsershots', ['resetEffects']),
    resetSingleEffect(key, value) {
      this.$store.commit('tools/browsershots/UPDATE_EFFECTS', {
        value,
        key,
      })
    },
  },
}
</script>
