const db = {
  browsers: [{
    id: 'simple-browser',
    name: 'Simple',
    image: require('@/assets/images/browsershots/browser.svg'),

    url: {
      switch: true,
      input: true,
      value: 'google.com',
    },
    title: {
      enabled: false,
      value: '',
    },
    theme: {
      switch: true,
      value: 'light',
    },
    padding: false,

  }, {

    id: 'chrome-browser',
    name: 'Chrome',
    image: require('@/assets/images/browsershots/chrome.svg'),

    url: {
      switch: false,
      input: true,
      value: 'google.com',
    },
    title: {
      enabled: true,
      value: 'Google',
    },
    theme: {
      switch: true,
      value: 'light',
    },
    padding: false,

  }, {

    id: 'safari-browser',
    name: 'Safari',
    image: require('@/assets/images/browsershots/safari.svg'),

    url: {
      switch: false,
      input: true,
      value: 'google.com',
    },
    title: {
      enabled: false,
      value: '',
    },
    theme: {
      switch: true,
      value: 'light',
    },
    padding: false,

  }],
}

export default db
