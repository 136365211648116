<template>
  <div class="nofile__app">
    <div>
      <v-alert class="text">
        Upload your file. <br>We don't upload or store any image on our servers
      </v-alert>

      <v-btn
        color="primary"
        small
        @click="$refs.imagePickerRef.click()"
      >
        Browse image
      </v-btn>

      <input
        ref="imagePickerRef"
        type="file"
        accept=".jpeg,.png,.jpg,GIF"
        :hidden="true"
        @change="uploadImage($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    uploadImage($event) {
      const input = $event.target
      const file = input.files
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.$store.commit('tools/browsershots/IMAGE_READY', reader.result)
        },
        false,
      )
      if (file && file[0]) {
        reader.readAsDataURL(file[0])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.nofile__app {
  background: white;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 10pt;
  }
}
</style>
